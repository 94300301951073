import Cookies from 'universal-cookie'
import API from '../Api'
import axios from 'axios'

const cookies = new Cookies()

const token = cookies.get('token')

const axiosNoUrl = axios.create()

const ApiHelper = {
  // fetch current user
  getToken() {
    return cookies.get('token')
  },
  isPlainObject(input) {
    // Check if input is object
    return input && !Array.isArray(input) && typeof input === 'object'
  },
  handleResponse(response, transformation) {
    if (response.status == 200 && !response.data) {
      return { response: 'Success' }
    }
    if (response.data.errors.length > 0) {
      throw response.data.errors
    }
    if (transformation) {
      return transformation(response.data.res)
    }
    return response.data.res
  },
  post(route, data, transformation = null) {
    return Promise.resolve(true)
      .then(() => {
        API.defaults.headers.common.Authorization = `Bearer ${ApiHelper.getToken()}`

        return API.post(route, {
          ...data,
        })
      })
      .then((response) => ApiHelper.handleResponse(response, transformation))
  },
  postWithFormData(route, data, transformation = null) {
    return Promise.resolve(true)
      .then(() => {
        API.defaults.headers.common.Authorization = `Bearer ${ApiHelper.getToken()}`

        const { formData, ...params } = data

        return API.post(route, formData, {
          params: params,
        })
      })
      .then((response) => ApiHelper.handleResponse(response, transformation))
  },
  get(route, transformation = null) {
    return Promise.resolve(true).then(() => {
      API.defaults.headers.common.Authorization = `Bearer ${ApiHelper.getToken()}`
      return API.get(route).then((response) => ApiHelper.handleResponse(response, transformation))
    })
  },
  getPaginated(route, transformation = null, data) {
    const { page, searchKey, searchValue, orderByValue, filters, projectId, user_id } = data

    return Promise.resolve(true).then(() => {
      API.defaults.headers.common.Authorization = `Bearer ${ApiHelper.getToken()}`
      return API.get(route, {
        params: {
          page,
          ...(searchKey !== '' && { searchKey: searchKey }),
          ...(searchValue !== '' && { search: searchValue }),
          ...(orderByValue !== '' && { order_by: orderByValue }),
          ...(Object.keys(filters || {}).length && filters),
          ...(projectId !== '' && { projectId }),
          ...(user_id !== '' && { user_id }),
        },
      }).then((response) => {
        const dataToRearange = response.data.res.data
        if (ApiHelper.isPlainObject(dataToRearange)) {
          response.data.res.data = Object.values(dataToRearange)
        }
        return ApiHelper.handleResponse(response, transformation)
      })
    })
  },

  put(route, data, transformation = null) {
    return Promise.resolve(true)
      .then(() => {
        return axiosNoUrl.put(route, data)
      })
      .then((response) => ApiHelper.handleResponse(response, transformation))
  },

  uploadFileToAWSS3(url, file) {
    return ApiHelper.put(url, file)
  },

  getDistBackupTempURL() {
    const transformation = (data) => ({
      url: data.url,
      path: data.path,
    })

    return ApiHelper.get('api/admin/distributions/getDistBackupTempURL', transformation)
  },

  distBackupRestore(data) {
    return ApiHelper.post('api/admin/distributions/distBackupRestore', { ...data })
  },

  getUsers(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((userData) => {
        return {
          id: userData.id,
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          company: userData.company,
          industry: userData.industry,
          occupation: userData.occupation,
          phoneNumber: userData.phone_number,
          role: userData.role,
          createdAt: userData.created_at,
          updatedAt: userData.updated_at,
          deletedAt: userData.deleted_at,
          hardDeletedAt: userData.hard_deleted_at,
          plan_name: userData.plan_name,
          // total_paid_usd: userData.total_amount_usd,
          // total_paid_eur: userData.total_amount_eur,
          // total_paid_gbp: userData.total_amount_gbp,
        }
      })

      return {
        users: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }
    return ApiHelper.getPaginated('/api/admin/users/all', transformation, data)
  },
  getUnpaidBills(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((billData) => {
        return {
          id: billData.id,
          amount: billData.amount,
          discountAmount: billData.discount_amount,
          startDate: billData.start_date,
          endDate: billData.end_date,
          planName: billData.plan_name,
          status: billData.status,
          subscriptionId: billData.subscription_id,
          createdAt: billData.created_at,
          updatedAt: billData.updated_at,
        }
      })

      return {
        bills: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }
    return ApiHelper.getPaginated(`/api/admin/bills/unpaid/${data.userId}`, transformation, data)
  },
  getUser(appkey) {
    const transformation = (data) => ({
      firstname: data.user.firstname,
      lastname: data.user.lastname,
      email: data.user.email,
      token,
      id: data.user.id,
      showTutorial: data.user.tutorial_overlay,
      frontendTutorial: data.user.frontend_tutorial,
      frontendFirstInfo: data.user.frontend_first_info,
      imgSrc: data.user.img_src,
      maintenance_scheduled: data.user.maintenance_scheduled,
      maintenance_time: data.user.maintenance_time,
      maintenance_duration: data.user.maintenance_duration,
      collaboration_invites: data.user.collaboration_invites,
      hasPaymentData: data.user.has_payment_data.user,
      subscription: data.user.subscription,
      company: data.user.company,
      twoFAEnabled: data.user.twoFA_enabled,
      collaborators: data.user.collaborators,
      occupation: data.user.occupation,
      numberOfSolutions: data.user.number_of_solutions,
      notifications: data.user.notifications,
      application: data.application,
      userApplication: data.user_application,
    })
    /*if (appkey) {
      return ApiHelper.get('/api/admin/users/current?app_public_key=' + appkey, transformation)
    }*/
    return ApiHelper.get('/api/admin/users/current', transformation)
  },

  getUserToEdit(data) {
    const transformation = (transformationData) => {
      const userData = transformationData.user
      const hasMoreCycles = transformationData.hasMoreCycles
      return {
        id: userData.id,
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        company: userData.company,
        industry: userData.industry,
        occupation: userData.occupation,
        phoneNumber: userData.phone_number,
        role: userData.role,
        roleId: userData.role_id,
        subscription: userData.subscription,
        hasMoreCycles,
      }
    }

    return ApiHelper.get(`/api/admin/users/${data.id}/edit`, transformation)
  },

  getBillHistory(data, limit = 10, page = null) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((billData) => {
        return {
          id: billData.id,
          amount: billData.amount,
          discountAmount: `$${billData.discount_amount / 100.0}`,
          planName: billData.plan_name,
          status: billData.status,
          subscriptionId: billData.subscription_id,
          startDate: billData.start_date,
          endDate: billData.end_date,
          createdAt: billData.created_at,
          updatedAt: billData.updated_at,
          period: `${billData.start_date} - ${billData.end_date}`,
          total: `${billData.amount / 100.0 - billData.discount_amount / 100.0}`,
        }
      })

      return {
        plans: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    const needle = data.statusValue === '' ? 'all' : data.statusValue

    return ApiHelper.getPaginated(
      `/api/admin/bills/planBillHistory?userId=${data.id}&limit=${limit}&orderBy=${
        data.orderBy
      }&needle=${needle}${page ? `&page=${page}` : ''}`,
      transformation,
      data,
    )
  },

  payForPlanBill(data) {
    return ApiHelper.post(`api/admin/bills/payForPlanBill`, { ...data })
  },

  payAllPlanBills(data) {
    return ApiHelper.post(`api/admin/bills/payAllPlanBills`, data)
  },

  unpauseSubscription(data) {
    return ApiHelper.post(`api/admin/bills/unpauseSubscription`, data)
  },

  payForViewBill(data) {
    return ApiHelper.post(`api/admin/bills/payForViewBill`, data)
  },

  payAllViewBills(data) {
    return ApiHelper.post(`api/admin/bills/payAllViewBills`, data)
  },

  getTransactionByPlanBillId(planBillId) {
    return ApiHelper.get(`api/admin/bills/planBillTransaction/${planBillId}`)
  },

  getTransactionByViewsBillId(viewsBillId) {
    return ApiHelper.get(`api/admin/bills/viewsBillTransaction/${viewsBillId}`)
  },

  getAllViewsBills(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((billData) => {
        return {
          id: billData.id,
          amount: billData.amount,
          discountAmount: `$${billData.discount_amount / 100.0}`,
          planName: billData.plan_name,
          status: billData.status,
          subscriptionId: billData.subscription_id,
          startDate: billData.start_date,
          endDate: billData.end_date,
          createdAt: billData.created_at,
          updatedAt: billData.updated_at,
          totalViews: billData.total_views,
          additionalViews: billData.additional_views,
          period: `${billData.start_date} - ${billData.end_date}`,
          total: `${billData.amount / 100.0 - billData.discount_amount / 100.0}`,
          firstname: billData.firstname,
          lastname: billData.lastname,
          email: billData.email,
        }
      })

      return {
        views: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    return ApiHelper.getPaginated('api/admin/bills/allViewsBills', transformation, data)
  },

  getViewHistory(data, limit = 10, page = null) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((billData) => {
        return {
          id: billData.id,
          amount: billData.amount,
          discountAmount: `$${billData.discount_amount / 100.0}`,
          planName: billData.plan_name,
          status: billData.status,
          subscriptionId: billData.subscription_id,
          startDate: billData.start_date,
          endDate: billData.end_date,
          createdAt: billData.created_at,
          updatedAt: billData.updated_at,
          totalViews: billData.total_views,
          additionalViews: billData.additional_views,
          period: `${billData.start_date} - ${billData.end_date}`,
          total: `${billData.amount / 100.0 - billData.discount_amount / 100.0}`,
        }
      })

      return {
        views: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    const needle = data.statusValue === '' ? 'all' : data.statusValue

    return ApiHelper.getPaginated(
      `api/admin/bills/viewsBillHistory?userId=${data.id}&limit=${limit}&orderBy=${
        data.orderBy
      }&needle=${needle}${page ? `&page=${page}` : ''}`,
      transformation,
      data,
    )
  },

  getUserSubscription(data) {
    return ApiHelper.get(`api/admin/subscriptions/userSubscription?userId=${data.userId}`)
  },
  cancelUserSubscription(data) {
    return ApiHelper.get(`api/admin/subscriptions/cancel?userId=${data.userId}`)
  },

  updateUser(userData) {
    return ApiHelper.post(`/api/admin/users/update/${userData.id}`, { ...userData })
  },
  changePassword(userData) {
    return ApiHelper.post(`/api/admin/users/changePassword/${userData.id}`, { ...userData })
  },
  changeEmail(userData) {
    return ApiHelper.post(`/api/admin/users/changeEmail/${userData.id}`, { ...userData })
  },
  createUser(userData) {
    return ApiHelper.post('/api/admin/users/create', { ...userData })
  },
  updateSubscription(userData) {
    return ApiHelper.post(`/api/admin/subscriptions/update/${userData.id}`, { ...userData })
  },
  logoutUser() {
    return new Promise((resolve, reject) => {
      API.defaults.headers.common.Authorization = `Bearer ${ApiHelper.getToken()}`
      API.get('api/admin/logout')
        .then((res) => {
          if (res.data.errors.length > 0) {
            reject(res.data.errors)
          }
          resolve(res)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  },
  loginUser(data) {
    const transformation = (dataRes) => {
      if (typeof dataRes.twoFA_enabled !== 'undefined') {
        return {
          show2FA: true,
          twoFAEnabled: dataRes.twoFA_enabled,
          twoFAToken: dataRes.twoFA_token,
        }
      }
      return {
        firstname: dataRes.userData.firstname,
        lastname: dataRes.userData.lastname,
        email: dataRes.userData.email,
        token: dataRes.token,
        id: dataRes.userData.id,
        showTutorial: dataRes.userData.tutorial_overlay,
        frontendTutorial: dataRes.userData.frontend_tutorial,
        frontendFirstInfo: dataRes.userData.frontend_first_info,
        imgSrc: dataRes.userData.img_src,
        maintenance_scheduled: dataRes.userData.maintenance_scheduled,
        maintenance_time: dataRes.userData.maintenance_time,
        maintenance_duration: dataRes.userData.maintenance_duration,
        collaboration_invites: dataRes.userData.collaboration_invites,
        hasPaymentData: data.has_payment_data,
        subscription: dataRes.userData.subscription,
        company: dataRes.userData.company,
        twoFAEnabled: dataRes.userData.twoFA_enabled,
        show2FA: false,
        collaborators: dataRes.userData.collaborators,
        occupation: dataRes.userData.occupation,
        numberOfSolutions: dataRes.userData.number_of_solutions,
        notifications: dataRes.userData.notifications,
        application: dataRes.application,
        userApplication: dataRes.user_application,
      }
    }

    return ApiHelper.post('api/admin/login', data, transformation)
  },
  getActivities(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((activityData) => {
        return {
          id: activityData.id,
          adminId: activityData.adminId,
          adminFirstname: activityData.adminFirstname,
          adminLastname: activityData.adminLastname,
          adminEmail: activityData.adminEmail,
          adminIp: activityData.adminIp,
          action: activityData.action,
          diffData: activityData.diff_data,
          comment: activityData.comment,
          createdAt: activityData.created_at,
        }
      })

      return {
        activities: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    return ApiHelper.getPaginated('/api/admin/activities/all', transformation, data)
  },

  updateActivityComment(activityData) {
    return ApiHelper.post(`/api/admin/activities/comment/${activityData.id}`, {
      ...activityData,
    })
  },

  getAllPlans(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((planData) => {
        return {
          id: planData.id,
          active: planData.active,
          name: planData.name,
          description: planData.description,
          price_usd: planData.price_usd,
          yearly_price_usd: planData.yearly_price_usd,
          price_gbp: planData.price_gbp,
          yearly_price_gbp: planData.yearly_price_gbp,
          price_eur: planData.price_eur,
          yearly_price_eur: planData.yearly_price_eur,
          customPlan: planData.custom_plan,
          freePlan: planData.free_plan,
          stripePlanId: planData.stripe_plan_id,
          stripePlanYearlyId: planData.stripe_plan_yearly_id,
          solutions: planData.solutions,
          ar: planData.ar,
          vr: planData.vr,
          views: planData.views,
          extraViews: planData.extra_views,
          viewsPriceUsd: planData.views_price_usd,
          viewsPriceGbp: planData.views_price_gbp,
          viewsPriceEur: planData.views_price_eur,
          optimizationPoints: planData.optimization_points,
          createdAt: planData.created_at,
          updatedAt: planData.updated_at,
        }
      })

      return {
        plans: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    return ApiHelper.getPaginated('/api/admin/plans/all', transformation, data)
  },

  getActivePlans() {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((planData) => {
        return {
          id: planData.id,
          name: planData.name,
          solutions: planData.solutions,
          views: planData.views,
        }
      })

      return {
        plans: [...transformedData],
      }
    }

    return ApiHelper.get('/api/admin/plans/active', transformation)
  },

  getEnterprise() {
    const transformation = (responseData) => {
      const transformedData = {
        id: responseData.id,
        name: responseData.name,
        active: responseData.active,
        solutions: responseData.solutions,
        views: responseData.views,
        price_usd: responseData.price_usd,
        yearly_price_usd: responseData.yearly_price_usd,
        price_gbp: responseData.price_gbp,
        yearly_price_gbp: responseData.yearly_price_gbp,
        price_eur: responseData.price_eur,
        yearly_price_eur: responseData.yearly_price_eur,
        customPlan: responseData.custom_plan,
        freePlan: responseData.free_plan,
        extraViews: responseData.extra_views,
        ar: responseData.ar,
        vr: responseData.vr,
        optimizationPoints: responseData.optimization_points,
        views_price_usd: responseData.views_price_usd,
        views_price_gbp: responseData.views_price_gbp,
        views_price_eur: responseData.views_price_eur,
      }

      return {
        plan: transformedData,
      }
    }

    return ApiHelper.get('/api/admin/plans/enterprise', transformation)
  },

  createCustomPlanProposal(planData) {
    return ApiHelper.post('/api/admin/subscriptions/createCustom', { ...planData })
  },

  getChangeLogs(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((logData) => {
        return {
          id: logData.id,
          type: logData.type,
          version: logData.version,
          imgSrc: logData.imgSrc,
          body: logData.body,
          createdAt: logData.createdAt,
          updatedAt: logData.updatedAt,
        }
      })

      return {
        changeLogs: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    return ApiHelper.getPaginated('api/admin/changelogs/all', transformation, data)
  },

  getLogs(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((logData) => {
        return {
          id: logData._id,
          type: logData.type,
          priority: logData.priority,
          service: logData.service,
          private: logData.private,
          privateServiceName: logData.private_service_name,
          code: logData.code,
          message: logData.message,
          serviceTimeFrom: logData.service_time_from,
          serviceTimeTo: logData.service_time_to,
          receivedTimeFrom: logData.received_time_from,
          receivedTimeTo: logData.received_time_to,
          userId: logData.user_id,
          projectId: logData.project_id,
          solutionId: logData.solution_id,
          distId: logData.dist_id,
          device: logData.device,
          browser: logData.browser,
          browserVersion: logData.browser_version,
          os: logData.os,
          osVersion: logData.os_version,
          libraryVersion: logData.library_version,
          viewId: logData.view_id,
          custom1: logData.custom_1,
          custom2: logData.custom_2,
          custom3: logData.custom_3,
          custom4: logData.custom_4,
          custom5: logData.custom_5,
          custom6: logData.custom_6,
          custom7: logData.custom_7,
          custom8: logData.custom_8,
          custom9: logData.custom_9,
          custom10: logData.custom_10,
          version: logData.__v,
          receivedTime: logData.received_time,
          createdAt: logData.created_at,
          updatedAt: logData.updated_at,
        }
      })

      return {
        logs: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    return ApiHelper.getPaginated('api/admin/logs/all', transformation, data)
  },

  getChangeLogToEdit(data) {
    const transformation = (transformationData) => {
      const changeLogData = transformationData.changeLog
      return {
        id: changeLogData.id,
        type: changeLogData.type,
        version: changeLogData.version,
        imgSrc: changeLogData.imgSrc,
        body: changeLogData.body,
      }
    }

    return ApiHelper.get(`/api/admin/changelogs/${data.id}/edit`, transformation)
  },

  createChangeLog(changeLogData) {
    return ApiHelper.post('/api/admin/changelogs/create', { ...changeLogData })
  },

  updateChangeLog(changeLogData) {
    return ApiHelper.post(`/api/admin/changelogs/update/${changeLogData.id}`, { ...changeLogData })
  },

  getActiveStripe(userId) {
    return ApiHelper.get(`/api/admin/subscriptions/getActiveStripe/${userId}`)
  },

  getAllSubscriptionsForUser(userId) {
    return ApiHelper.get(`/api/admin/subscriptions/getAllForUser/?userId=${userId}`)
  },

  getProjects(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((projectData) => {
        return {
          id: projectData.id,
          name: projectData.title,
          owner_id: projectData.user_id,
          owner: projectData.owner.first_name + ' ' + projectData.owner.last_name,
          email: projectData.owner.email,
          created_at: projectData.created_at,
          updated_at: projectData.updated_at,
        }
      })

      return {
        projects: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }
    return ApiHelper.getPaginated('/api/admin/projects/all', transformation, data)
  },

  getProject(projectId) {
    return ApiHelper.get(`api/admin/projects/${projectId}`)
  },

  addCollaborator(id, emails) {
    return ApiHelper.post(`api/admin/projects/share/${id}`, { emails })
  },

  removeCollaborator(id, collaborator) {
    return ApiHelper.post(`api/admin/projects/removeCollaborator/${id}`, { collaborator })
  },

  addCollaboratorImmediately(id, collaborator) {
    return ApiHelper.post(`api/admin/projects/addCollaboratorImmediately/${id}`, { collaborator })
  },

  getSolutions(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((solutionData) => {
        return {
          id: solutionData.id,
          title: solutionData.title,
          device: solutionData.device,
          status: solutionData.status,
          description: solutionData.description,
          owner: solutionData.owner,
          project: solutionData.project,
          projectId: solutionData.project_id,
          createdAt: solutionData.created_at,
          updatedAt: solutionData.updated_at,
        }
      })

      return {
        solutions: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }

    return ApiHelper.getPaginated('api/admin/solutions', transformation, data)
  },

  solutionTufDownload(id) {
    return ApiHelper.post(`api/admin/solutions/tufDownload/`, { id })
  },

  solutionTufUpload(data) {
    return ApiHelper.postWithFormData(`api/admin/solutions/tufUpload/`, data)
  },

  getDashboardInfo() {
    return ApiHelper.get('api/admin/dashboard')
  },

  getUsersWithUnpaidBills(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((userData) => {
        return {
          id: userData.id,
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          company: userData.company,
          industry: userData.industry,
          occupation: userData.occupation,
          phoneNumber: userData.phone_number,
          role: userData.role,
          createdAt: userData.created_at,
          plan_name: userData.plan_name,
        }
      })

      return {
        users: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }
    return ApiHelper.getPaginated('api/admin/users/unpaid', transformation, data)
  },

  getUsersWithSubExpireWithinSevenDays(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((userData) => {
        return {
          id: userData.id,
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          company: userData.company,
          industry: userData.industry,
          occupation: userData.occupation,
          phoneNumber: userData.phone_number,
          role: userData.role,
          createdAt: userData.created_at,
          plan_name: userData.plan_name,
        }
      })

      return {
        users: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }
    return ApiHelper.getPaginated('api/admin/users/subEnding', transformation, data)
  },

  getSolution(solutionId) {
    const transformation = (transformationData) => {
      const solutionData = transformationData
      return {
        id: solutionData.id,
        title: solutionData.title,
        device: solutionData.device,
        status: solutionData.status,
        description: solutionData.description,
        owner: solutionData.owner,
        project: solutionData.project,
        projectId: solutionData.project_id,
        createdAt: solutionData.created_at,
        updatedAt: solutionData.updated_at,
      }
    }

    return ApiHelper.get(`api/admin/solutions/${solutionId}`, transformation)
  },

  getSubscriptionsHistory(data) {
    const transformation = (responseData) => {
      const transformedData = responseData.data.map((subscriptionData) => {
        return {
          userId: subscriptionData.userId,
          subId: subscriptionData.subscription_id,
          firstname: subscriptionData.firstname,
          lastname: subscriptionData.lastname,
          email: subscriptionData.email,
          company: subscriptionData.company,
          industry: subscriptionData.industry,
          occupation: subscriptionData.occupation,
          createdAt: subscriptionData.created_at,
          planId: subscriptionData.plan_id,
          planName: subscriptionData.plan_name,
          planPrice: subscriptionData.plan_price,
          planYearlyPrice: subscriptionData.plan_yearly_price,
          startDate: subscriptionData.start_date,
          endDate: subscriptionData.end_date,
          status: subscriptionData.status,
          solutions: subscriptionData.solutions,
          views: subscriptionData.views,
          extraViews: subscriptionData.extra_views,
          optimizationPoints: subscriptionData.optimization_points,
        }
      })

      return {
        users: {
          data: [...transformedData],
          ...responseData.pagination,
        },
      }
    }
    return ApiHelper.getPaginated('api/admin/reports/users/subscriptions', transformation, data)
  },

  logoutUserAdmin(userId) {
    return ApiHelper.get(`api/admin/users/logout/${userId}`)
  },

  loginUserAdmin(userId) {
    return ApiHelper.get(`api/admin/users/login/${userId}`)
  },

  deletePlanBill(planBillID) {
    return ApiHelper.get(`api/admin/bills/deletePlanBill/${planBillID}`)
  },

  getDists(solutionID) {
    return ApiHelper.get(`api/admin/solutions/publish/${solutionID}`)
  },

  exportDists() {
    return ApiHelper.get(`api/admin/distributions/export`)
  },

  /*importDists(data) {
    return ApiHelper.postWithFormData(`api/admin/distributions/import`, data)
  },*/

  createPlan(planData) {
    return ApiHelper.post('/api/admin/plans/create', { ...planData })
  },

  getPlan(id) {
    const transformation = (responseData) => {
      const transformedData = {
        id: responseData.id,
        name: responseData.name,
        active: responseData.active,
        description: responseData.description,
        solutions: responseData.solutions,
        views: responseData.views,
        price_usd: responseData.price_usd,
        yearly_price_usd: responseData.yearly_price_usd,
        price_gbp: responseData.price_gbp,
        yearly_price_gbp: responseData.yearly_price_gbp,
        price_eur: responseData.price_eur,
        yearly_price_eur: responseData.yearly_price_eur,
        customPlan: responseData.custom_plan,
        freePlan: responseData.free_plan,
        stripePlanId: responseData.stripe_plan_id,
        stripePlanYearlyId: responseData.stripe_plan_yearly_id,
        extraViews: responseData.extra_views,
        ar: responseData.ar,
        vr: responseData.vr,
        optimizationPoints: responseData.optimization_points,
        views_price_usd: responseData.views_price_usd,
        views_price_gbp: responseData.views_price_gbp,
        views_price_eur: responseData.views_price_eur,
      }

      return {
        plan: transformedData,
      }
    }

    return ApiHelper.get(`/api/admin/plans/${id}/edit`, transformation)
  },
  editPlan(id, planData) {
    return ApiHelper.post(`/api/admin/plans/update/${id}`, { ...planData })
  },
  getTotalUsers() {
    return ApiHelper.get(`api/admin/users/total`)
  },
  downgradeToFree(id) {
    return ApiHelper.get(`api/admin/subscriptions/downgradeToFree/${id}`)
  },
  deleteUser(userId) {
    return ApiHelper.get(`api/admin/users/delete/${userId}`)
  },
  restoreUser(userId) {
    return ApiHelper.get(`api/admin/users/restore/${userId}`)
  },
  hardDeleteUser(userId) {
    return ApiHelper.get(`api/admin/users/hardDelete/${userId}`)
  },
  updateUserRole(userId, roleId) {
    return ApiHelper.get(`api/admin/users/updateRole/${userId}/${roleId}`)
  },
  getAllRoles() {
    return ApiHelper.get(`api/admin/roles`)
  },
}

export default ApiHelper
